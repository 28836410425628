/*  Custom loader starts */

.bViQzk {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin: 0 2px;
  display: inline-block;
  -webkit-animation: ghjfcd 1.4s infinite ease-in-out both;
  animation: ghjfcd 1.4s infinite ease-in-out both;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.bLLmrZ {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin: 0 2px;
  display: inline-block;
  -webkit-animation: ghjfcd 1.4s infinite ease-in-out both;
  animation: ghjfcd 1.4s infinite ease-in-out both;
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.ebGjrP {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin: 0 2px;
  display: inline-block;
  -webkit-animation: ghjfcd 1.4s infinite ease-in-out both;
  animation: ghjfcd 1.4s infinite ease-in-out both;
}

/* sc-component-id: sc-keyframes-ghjfcd */
@-webkit-keyframes ghjfcd {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1.0);
    -ms-transform: scale(1.0);
    transform: scale(1.0);
  }
}

@keyframes ghjfcd {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1.0);
    -ms-transform: scale(1.0);
    transform: scale(1.0);
  }
}

/*    Custom loader ends  */