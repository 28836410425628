
  /* for radio button css */

  .modern-radio-container {
    margin: 10px 0;
    display: flex;
    cursor: pointer;
    user-select: none;
  }

  .radio-container-border {
    border: 1px solid #DCDCDC;
    border-radius: 5px;
    padding : 15px;
    box-shadow: 0 5px 5px -6px #DCDCDC;
  }
  
  .radio-outer-circle {
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    border: 2px solid #0d5bc6;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    transition: all 0.1s linear;
  }
  
  .radio-inner-circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #0d5bc6;
    transition: all 0.1s linear;
  }
  .unselected {
    border: 2px solid #666;
  }
  .unselected-circle {
    width: 0;
    height: 0;
  }
  .helper-text {
    margin-top: -3px;
    font-size: 15px;
    padding-right: 8px;
    width: 100%;
  }
