@font-face {
  font-family: 'montserratbold';
  src: url('static/fonts/montserrat/font_bold.woff2') format('woff2'),
    url('static/fonts/montserrat/font_bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'montserratmedium';
  src: url('static/fonts/montserrat/font_medium.woff2') format('woff2'),
    url('static/fonts/montserrat/font_medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'montserratregular';
  src: url('static/fonts/montserrat/font_regular.woff2') format('woff2'),
    url('static/fonts/montserrat/font_regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'robotobold';
  src: url('static/fonts/roboto/font_bold.woff2') format('woff2'),
    url('static/fonts/roboto/font_bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'robotomedium';
  src: url('static/fonts/roboto/font_medium.woff2') format('woff2'),
    url('static/fonts/roboto/font_medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'robotoregular';
  src: url('static/fonts/roboto/font_regular.woff2') format('woff2'),
    url('static/fonts/roboto/font_regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'poppinsbold';
  src: url('static/fonts/poppins/font_bold.woff2') format('woff2'),
    url('static/fonts/poppins/font_bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'poppinsmedium';
  src: url('static/fonts/poppins/font_medium.woff2') format('woff2'),
    url('static/fonts/poppins/font_medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'poppinsregular';
  src: url('static/fonts/poppins/font_regular.woff2') format('woff2'),
    url('static/fonts/poppins/font_regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'navisansbold';
  src: url('static/fonts/navisans/font_bold.woff2') format('woff2'),
    url('static/fonts/navisans/font_bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'navisansmedium';
  src: url('static/fonts/navisans/font_medium.woff2') format('woff2'),
    url('static/fonts/navisans/font_medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'navisansregular';
  src: url('static/fonts/navisans/font_regular.woff2') format('woff2'),
    url('static/fonts/navisans/font_regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'interbold';
  src: url('static/fonts/inter/font_bold.woff2') format('woff2'),
    url('static/fonts/inter/font_bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'intermedium';
  src: url('static/fonts/inter/font_medium.woff2') format('woff2'),
    url('static/fonts/inter/font_medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'interregular';
  src: url('static/fonts/inter/font_regular.woff2') format('woff2'),
    url('static/fonts/inter/font_regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'opensansbold';
  src: url('static/fonts/opensans/font_bold.woff2') format('woff2'),
    url('static/fonts/opensans/font_bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'opensansmedium';
  src: url('static/fonts/opensans/font_medium.woff2') format('woff2'),
    url('static/fonts/opensans/font_medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'opensansregular';
  src: url('static/fonts/opensans/font_regular.woff2') format('woff2'),
    url('static/fonts/opensans/font_regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'helveticabold';
  src: url('static/fonts/helvetica/font_bold.woff2') format('woff2'),
    url('static/fonts/helvetica/font_bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'helveticamedium';
  src: url('static/fonts/helvetica/font_medium.woff2') format('woff2'),
    url('static/fonts/helvetica/font_medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'helveticaregular';
  src: url('static/fonts/helvetica/font_regular.woff2') format('woff2'),
    url('static/fonts/helvetica/font_regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'latobold';
  src: url('static/fonts/lato/font_bold.woff2') format('woff2'),
    url('static/fonts/lato/font_bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'latomedium';
  src: url('static/fonts/lato/font_medium.woff2') format('woff2'),
    url('static/fonts/lato/font_medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'latoregular';
  src: url('static/fonts/lato/font_regular.woff2') format('woff2'),
    url('static/fonts/lato/font_regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'dmsansbold';
  src: url('static/fonts/dmsans/font_bold.woff2') format('woff2'),
    url('static/fonts/dmsans/font_bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'dmsansmedium';
  src: url('static/fonts/dmsans/font_medium.woff2') format('woff2'),
    url('static/fonts/dmsans/font_medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'dmsansregular';
  src: url('static/fonts/dmsans/font_regular.woff2') format('woff2'),
    url('static/fonts/dmsans/font_regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'finno', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*   Autofill background removing start */

@keyframes autofill {

  0%,
  100% {
    background: transparent;
  }
}

input:-webkit-autofill {
  animation-delay: 1s;
  /* Safari support - any positive time runs instantly */
  animation-name: autofill;
  animation-fill-mode: both;
}

/* Autofill background removing end */


/* Buttons style start */

.very-small-primary-button {
  width: 55px;
  height: 25px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 10px;
  border-width: 2px;
  border-style: solid;
  line-height: 0px;
  padding: 0px;
  /* font-family: 'fontsemibold'; */
}

.small-primary-button {
  width: auto;
  height: 30px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  border-width: 2px;
  border-style: solid;
  line-height: 0px;
  cursor: pointer;
  /* font-family: 'fontsemibold'; */
}

.primary-button {
  width: 48%;
  max-width: 300px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  border-width: 2px;
  border-style: solid;
  /* font-family: 'fontsemibold'; */
}

.error-button {
  width: 48%;
  max-width: 300px;
  border-radius: 8px;
  margin-right: 4%;
  color: #000000;
  font-weight: 600;
  font-size: 16px;
  background-color: white;
  border-width: 2px;
  border-style: solid;
  /* font-family: 'fontsemibold'; */
}

.full-primary-button {
  width: 48%;
  max-width: 300px;
  font-weight: 600;
  padding: 10px;
  border-radius: 8px;
  font-size: 16px;
  border-width: 2px;
  border-style: solid;
  /* font-family: 'fontsemibold'; */
}

.full-error-button {
  width: 48%;
  max-width: 300px;
  margin-right: 4%;
  font-weight: 600;
  padding: 10px;
  color: #000000;
  border-width: 2px;
  border-style: solid;
  border-radius: 8px;
  font-size: 16px;
  background-color: white;
  /* font-family: 'fontsemibold'; */
}

/* Header style start */

.top-bar {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: white;
  padding: 0px;
  box-sizing: border-box;
  height: 50px;
  box-shadow: 0 5px 5px -6px black;
  z-index: 3;
}

.header-logo {
  position: absolute;
  left: 50%;
  /*margin-left: -50px;*/
  transform: translate(-50%, 0);
}

/* Header style end */

/* Footer style start */

.footer-bar {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 35px;
  text-align: center;
  padding: 5px;
  /*background-color: #333;
  color: #fff;*/
}

.footer-powered-by {
  font-size: 12px;
}

/* @media screen and (min-height: 650px) {
  .footer-bar {
    position: fixed;
    bottom: 0px;
  }
} */

/* Footer style end */

/* Popup style start */
.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 4;
}

.popup_inner {
  width: 95%;
  position: relative;
  max-width: 900px;
  height: 80%;
  margin: auto;
  margin-top: 5%;
  margin-bottom: 5%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: white;
  overflow: auto;
}

.frame_style {
  width: 100%;
  height: 100%;
}

.close-icon {
  position: absolute;
  right: 15px;
  top: 22px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.popup_header {
  position: fixed;
  margin: auto;
  width: 95%;
  max-width: 900px;
  background-color: white;
  padding: 20px;
  padding-bottom: 10px;
}

.popup_content {
  overflow: auto;
  padding: 20px;
  padding-top: 60px;
}

/* Popup style end */


@media screen and (max-width: 600px) {
  .full-primary-button {
    width: 100%;
    margin: 0;
    margin-top: 20px;
    max-width: 100%;
  }

  .full-error-button {
    width: 100%;
    margin: 0;
    margin-top: 20px;
    max-width: 100%;
  }

  .bottomDiv, .bottomTextDiv {
    position: absolute;
    bottom: 20px;
    width: 100%;
    left: 0px;
    padding: 20px;
  }

  .popup_inner {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 60px;
    bottom: 20px;
  }

  .header-logo {
    position: absolute;
    left: 0%;
    margin-left: 20px;
    transform: translate(0, 0);
  }
}

@media screen and (max-height: 600px) {
  .bottomTextDiv {
    position: relative;
    bottom: auto;
  }
}

/* Card style start */

.normal-card {
  border: 0px solid #faf8f8;
  max-width: 500px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
}

.action-card {
  border: 0px solid #faf8f8;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

.card-title {
  color: #333333;
  font-size: 19px;
  font-weight: 500;
}

.card-action-title {
  color: #191919;
  font-size: 20px;
  font-weight: normal;
}

.card-action-subtitle {
  color: #191919;
  font-size: 16px;
  font-weight: normal;
}

.card-msg {
  font-size: 14px;
  font-weight: 500;
}

.card-msg-black {
  color: #212121;
  font-size: 12px;
  margin-top: 10px;
}

.card-action-msg {
  font-size: 12px;
  /* font-weight: 600; */
}

/* Card style end */

/* input style starts */

.action-input-style {
  display: block;
  width: 100%;
  padding-left: 10px;
  font-weight: 400;
  /* line-height: 1.5; */
  background-clip: padding-box;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  background-color: #ffffff;
  font-size: 14px;
  border-radius: 8px;
  height: 40px;
  line-height: 3.5;
  /* color: #9da1b9; */
  color: #797e96;
  border: 1.2px solid #d3d8de;
  font-weight: 600;
  appearance: none;
  /* font-family: 'fontsemibold'; */
}

.input-style {
  font-size: 14px;
  background: none;
  border: none;
  width: 100%;
  box-shadow: none;
  padding-right: 30px;
  outline: none;
  color: #22202e;
  font-weight: 500;
  /* font-family: 'fontmedium'; */
  appearance: none;
}

.input-disabled-style {
  font-size: 14px;
  background: none;
  border: none;
  width: 100%;
  box-shadow: none;
  padding-right: 30px;
  outline: none;
  color: #22202e;
  font-weight: 500;
  /* font-family: 'fontmedium'; */
  appearance: none;
  pointer-events: none;
}

.input-div-style {
  background-color: #ffffff;
  border-radius: 2px;
  position: relative;
  border-bottom: 2px solid #c8d0ef;
  font-weight: 500;
  /* font-family: 'fontmedium'; */
}

.input-div-err-style {
  background-color: #ffffff;
  border-radius: 2px;
  position: relative;
  border-bottom: 2px solid #de0808;
  font-weight: 500;
  /* font-family: 'fontmedium'; */
}

.optionStyle {
  margin: 20px;
  padding: 20px;
  height: 100px;
  line-height: 100px;
}

.institution_note {
  /* color: #6b6f86; */
  font-size: 12px;
  vertical-align: middle;
  font-weight: 500;
  word-break: break-word;
}

.institution_note_heading {
  color: #000000;
  font-size: 12px;
  vertical-align: middle;
  font-weight: 500;
}

.destinationTitle {
  /* color: #6b6f86; */
  font-size: 12px;
  vertical-align: middle;
  font-weight: 500;
}

.homeTextFieldTitle {
  /* color: #6b6f86; */
  font-size: 14px;
  vertical-align: middle;
  font-weight: 500;
  /* font-family: 'montserratmedium'; */
}

.infoTextStyle {
  font-size: 12px;
  margin: 0;
  font-weight: normal;
  /* font-family: 'fontmedium'; */
  line-height: 1.5;
}

.infoTitleStyle {
  font-size: 12px;
  margin: 0;
  /* color: var(--info-title-text-color); */
  font-weight: 600;
  line-height: 1.5;
  /* font-family: 'fontsemibold'; */
}

.float-right-icon {
  position: absolute;
  top: 50%;
  right: 7px;
  transform: translate(0%, -50%);
  z-index: 2;
  /*this will keep the icon appearing all time, even when on input::focus*/
}

.float-right-aa-text {
  position: absolute;
  right: 10px;
  top: 5px;
  z-index: 2;
  /*this will keep the icon appearing all time, even when on input::focus*/
}

.float-right-eye-icon {
  position: absolute;
  right: 10px;
  top: 20px;
  z-index: 2;
  /*this will keep the icon appearing all time, even when on input::focus*/
}


.otpTimeLeftStyle {
  /* color: #6b6f86; */
  font-size: 12px;
  font-weight: bold;
  float: right;
  font-weight: 500;
  /* font-family: 'fontmedium'; */
  padding-top: 10px;
}

.success-div {
  border: 2px solid #639665;
  box-shadow: 0 5px 5px -6px #DCDCDC;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
}

.info-div {
  border: 1px solid #757575;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
}

.hyperLinkStyle {
  font-size: 12px;
  color: #52a3c5;
  font-weight: normal;
  /* font-family: 'fontmedium'; */
  text-decoration: underline;
}

.termsStyle {
  font-size: 12px;
  /* color: #6b6f86; */
  font-weight: normal;
  /* font-family: 'fontmedium'; */
  vertical-align: middle;
  margin-top: 20px;
  margin-bottom: 40px;
}

.tcStyle {
  font-size: 14px;
  color: rgb(33, 33, 199);
  font-weight: normal;
  /* font-family: 'fontmedium'; */
  vertical-align: middle;
  margin-top: 20px;
  cursor: pointer;
  text-decoration: underline;
}

.homeDropdownIcon {
  width: 16px;
  height: auto;
  pointer-events: none;
}

.checkboxStyle {
  margin: 0;
}

.respImgStyle {
  width: auto;
  height: 73px;
  margin-bottom: 30px;
}

.maintenanceImgStyle {
  width: auto;
  height: 173px;
  margin-bottom: 30px;
}

.homeLockIconDiv {
  padding: 4px;
  position: relative;
  width: 70px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.homeLockIcon {
  padding-right: 1px;
  width: 23px;
  height: auto;
  color: #797e96;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}

.homeIcon {
  width: 20px;
  height: auto;
  margin-right: 10px;
}

.captchaImageStyle {
  align-items: center;
  width: 50%;
}

.homeInfo {
  /* border-left: 0.5px solid #c8d0ef; */
  padding: 10px;
  text-align: justify;
  display: inline-block;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.leftSideView {
  width: 50%;
  border-right: 2px solid grey;
  padding: 100px;
  display: inline-block;
}

.rightSideView {
  display: inline-block;
  padding-left: 50px;
}

@media screen and (max-width: 600px) {
  .leftSideView {
    width: 100%;
    border-right: 0px solid grey;
    padding: 10px;
  }

  .hideDiv {
    visibility: hidden;
  }
}

.instructionTitle {
  font-size: 14px;
  color: #464546;
}

.instructionText {
  font-size: 12px;
  color: #464546;
  word-break: break-word;
}

.pdf-icon {
  display: inline-block;
  max-width: 20px;
  width: auto;
  height: auto;
  margin-right: 10px;
}

.cancel-text {
  position: absolute;
  right: 0%;
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
}

.account-sub-heading {
  width: 140px;
  display: inline-block;
  line-height: 0;
  font-size: 14px;
}

.account-info {
  font-size: 12px;
  color: #191919;
}

ul {
  padding: 0px;
  margin: 0px;
}

/* for error screen table type */

table {
  border: 1px solid #757575;
  padding: 10px;
  width: 100%;
  max-width: 280px;
  margin: auto;
}

th {
  padding: 5px;
  border: 1px solid #757575;
}

td {
  padding: 5px;
  border: 1px solid #757575;
  font-size: 14px;
  font-weight: 500;
}

/* Mail statement password screen */

.password_hint_title {
  font-size: 12px;
}

.password_hint_desc {
  font-size: 12px;
}

.password_hint_or {
  font-size: 12px;
  text-align: center;
}


/* For email statement instructions page */

.email-type-icon {
  width: 30px;
  height: auto;
}

.email-type-text {
  color: #6b6f86;
  font-size: 15px;
  font-weight: 600;
  margin-left: 15px;
}

.email-type-layout {
  border: 1px solid #DCDCDC;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 5px 5px -6px #DCDCDC;
  margin-top: 5px;
}

.email-type-title {
  color: #6b6f86;
  font-size: 12px;
  vertical-align: middle;
  font-weight: 500;
}

/* View Files */
.file-date {
  /* color: #6b6f86; */
  font-size: 12px;
}

.table-title {
  font-size : 16px;
  color: #333333;
  font-weight: 500;
}

.account-number {
  color: #000000;
  font-size: 16px;
  margin-bottom: 10px;
}

.files-toggle {
  font-size: 14px;
}

.file-response-msg {
  margin-bottom: 10px;
}

.file-info-msg {
  margin-bottom: 10px;
  font-size: 12px;
}

.file-name-title {
  text-overflow: ellipsis;
  font-size: 14px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

.view-files-text {
  float: right;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
}

.file-name {
  word-break: break-word;
}

.user-action-icon {
  margin-bottom: 10px;
  width: 52px;
  height: auto;
}

.cancel-btn-text {
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}

.cancel-btn-div {
  padding-top: 5px;
}

.otp-input-container {
  display: flex;
  justify-content: center;
}

input.otp {
  width: 40px;
  height: 40px;
  font-size: 18px;
  text-align: center;
  margin: 0 5px;
  border: none;
  border-bottom: 3px solid #fff;
  background: none;
  color: #fff;
}

/* Style for focused input field */
input.otp:focus {
  outline: none;
}

.bank-name-div {
  text-align: center;
  padding: 10px;
  border-radius: 10px;
}

.bank-logo {
  vertical-align: middle;
  margin-right: 5px;
  width: 20px;
  height: auto;
}

.bank-name {
  vertical-align: middle;
  font-size: 14px;
}

.statement-file-div {
  padding : 12px;
  background-color : #ffffff;
  border-radius: 10px;
  box-shadow: 1px 1px 2px 2px #DCDCDC;
  margin-bottom: 10px;
  height: 50px;
  overflow: hidden;
}

.upload-more-icon {
  width: 50px;
  height: auto;
  cursor: pointer;
}

.upload-more-text {
  font-size: 14px;
  margin-left: 10px;
}

.statement-status-icon {
  width: 25px;
  height: auto;
  cursor: pointer;
}

.statement-upload-icon {
  width: 20px;
  height: auto;
  cursor: pointer;
}

.statement-bank-select {
  width: 100%;
  background-color: #ffffff;
  font-size: 14px;
  border: none;
  appearance: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  padding-right: 20px;
}

.statement-password-input {
  width: 100%;
  background-color: #ffffff;
  font-size: 14px;
  appearance: none;
  padding-left: 30px;
  padding-right: 10px;
  border: none;
}

.float-left-icon {
  position: absolute;
  top: 50%;
  left: 7px;
  transform: translate(0%, -50%);
  z-index: 2;
  /*this will keep the icon appearing all time, even when on input::focus*/
}

.statements-div {
  max-height: 500px;
  overflow: auto;
}

@media screen and (max-width: 600px) {
  .statements-div {
    max-height: 320px;
    overflow: auto;
  }
}

.statements-div::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.statements-div {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.stmt-upload-details-div::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.stmt-upload-details-div {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}