.popup_modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
  z-index: 10;
}

.popup_modal_content {
  width:95%;
  position: relative;
  margin: 0;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  height: auto;
  border-radius: 8px;
  background: white;
  overflow: auto;
  padding: 20px;
}

.close {
  cursor: pointer;
  color: black;
}

.popupTitleText{
  /* color: #191919; */
  font-size: 16px;
  text-align: center;
  margin-top:5px;
  font-style: bold;
  /* text-decoration: underline; */
}

.popupBodyText{
  /* color: #191919; */
  font-size: 14px;
  text-align: left;
  margin-top:5px;
  white-space: pre-line;
}

.popup-primary-button {
  width: auto;
  border-radius: 6px;
  font-weight: 600;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 16px;
  border-width: 2px;
  border-style: solid;
}

.popup-error-button  {
  width: auto;
  border-radius: 6px;
  margin-right: 4%;
  color: #000000;
  font-weight: 600;
  font-size: 16px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: white;
  border-width: 2px;
  border-style: solid;
}