.custom-dropdown {
    position: relative;
    display: inline-block;
    width: 200px;
  }
  
  .dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    background-color: #fff;
  }
  
  .selected-option {
    display: flex;
    align-items: center;
  }
  
  .option-logo {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  
  .dropdown-arrow {
    margin-left: 10px;
  }
  
  .dropdown-options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    z-index: 1;
  }
  
  .dropdown-option {
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .dropdown-option:hover {
    background-color: #f0f0f0;
  }
  